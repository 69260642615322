import { plainBtnStyles } from '@/buttons/Plain';
import { css } from '@emotion/react';
import { theme } from './styles/GlobalStyles';

const dynamicStyles = css`
    position: relative;

    ::before {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 3px;
        border-radius: 15px;
        background-color: ${theme.colors.blue4};
        transition: width 0.3s ease-in-out;
    }

    &[aria-current='page'] {
        ::before {
            width: 32px;
        }
    }

    &.current-nav-category {
        ::before {
            width: 32px;
        }
    }

    &[aria-expanded='true'] {
        > svg {
            transform: rotate(90deg);
        }

        ::before {
            width: 100%;
        }
    }

    @media (pointer: fine) {
        :hover {
            ::before {
                width: 100%;
            }

            > svg > path {
                fill: ${theme.colors.blue4};
            }
        }

        :focus-visible {
            ::before {
                width: 100%;
            }

            > svg > path {
                fill: ${theme.colors.blue4};
            }
        }
    }

    @media (min-width: 1280px) {
        &[aria-expanded='true'] {
            > svg {
                transform: rotate(180deg);
            }
        }
    }
`;

export const navLinkStyles = css`
    ${dynamicStyles};
    color: ${theme.colors.gray5};
    text-transform: capitalize;
    margin-bottom: 32px;
    transition: color 0.3s ease-in-out;
    font-weight: 400;
    line-height: 160%;

    @media (min-width: 1280px) {
        margin-bottom: 0 !important;
    }
`;

export const subNavLinkStyles = css`
    @media (min-width: 1280px) {
        color: ${theme.colors.gray5};
        text-transform: capitalize;
        display: block;
        padding: 16px 24px;
        margin: 0 !important;
        transition:
            color 0.3s ease-in-out,
            background-color 0.3s ease-in-out;

        :hover {
            color: ${theme.colors.blue4};
            background-color: ${theme.colors.blue1};

            > svg > path {
                fill: ${theme.colors.blue4};
            }
        }

        :focus-visible {
            color: ${theme.colors.blue4};
            background-color: ${theme.colors.blue1};

            > svg > path {
                fill: ${theme.colors.blue4};
            }
        }

        &[aria-current='page'] {
            color: ${theme.colors.blue4};
        }
    }
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};

    ::before {
        left: calc(50% - 9px);
    }

    &[aria-expanded='true'] {
        ::before {
            width: calc(100% - 18px);
        }
    }

    > svg {
        margin-left: 8px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${theme.colors.gray5};
        }
    }

    @media (pointer: fine) {
        :hover {
            ::before {
                width: calc(100% - 18px);
            }
        }

        :focus-visible {
            ::before {
                width: calc(100% - 18px);
            }
        }
    }

    @media (max-width: 1279px) {
        > svg {
            transform: rotate(-90deg);
        }
    }
`;

export const subNavBtnStyles = css`
    ${plainBtnStyles};
    ${subNavLinkStyles};
    font-weight: 400;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;

    &.current-sub-category {
        color: ${theme.colors.blue4};
    }

    > svg {
        transform: rotate(-90deg);
        margin-left: 10px;
    }
`;
